.header {
    margin-bottom: 20px;
    font-size: 2.5em;
    font-weight: lighter;
    text-align: center;
    margin-top: 20px;
}

.header img {
    vertical-align: middle;
}

.header-text {
    margin: 20px;
}
