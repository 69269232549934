body {
    width: 100%;
}


@media screen and (min-width: 601px) {
    .table-header {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {
    .table-header, .total, .part-row {
        font-size: 12px;
    }


    .Collapsible {
        width: 100%;
    }

    .header {
        font-size: 24px !important;
    }

    .image {
        width: 40px;
    }

    .image img {
        width: 40px;
        height: 40px;
    }

    .innerContainer {
        margin: 10px !important;
    }

    .trigger-image img {
        width: 50%;
        height: 50%;
    }

    .trigger-text {
        padding-left: 0 !important;
        width: 100%;
    }

    .trigger-text h2, .trigger-text p {
        text-align:  center !important;
    }
}

.App {
    width: 100%;
}

.youtube-container, .next-container, .ranking-container {
    text-align: center;
    margin-bottom: 20px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}


.partsContainer, .stepsContainer {
    flex: 1 0 50%;
}

.innerContainer {
    margin: 20px;
    text-align: center;
}

.innerContainer table {
    margin: 0 auto;
    width: 100%;
}

.table-header {
    font-weight: bold;
    background-color: #c1c1c1;
}


.table-header td {
    padding: 4px 8px;
}

.align-right {
    text-align: right;
}


.image img {
    border: 1px solid #bbb;
}

.total {
    font-weight: bold;
    background-color: #f0f0f0;
}

.missing {
    opacity: 20%;
}

.loadingCenter{
    width: 100%;
    text-align: center;
}

.chart-container {
    width: 100%;
    margin: 0 auto;
}

.custom-tooltip {
    background: #ffffff;
    padding: 6px 10px;
}
