.youtube-container {
    position: relative;
    max-width: 560px;
    margin: auto;
}

.youtube-container:before {
    content: "";
    display: block;
    padding-top: 56%;
}

iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;;
    left: 0;
    right: 0;
    bottom: 0;
}
