.revenue-container {
    background-color: #282c34;
    height: 100px;
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.item {
    font-weight: lighter;
    font-size: 36px;
    color: #fff;
    width: 50%;
    text-align: center;
}

.last-update {
    text-align: center;
    font-size: 12px;
    color: blue;
    margin-bottom: 20px;
}
