.stats-container {
    text-align: center;
    margin-bottom: 20px;
}

.stat-row {
    margin-bottom: 8px;
}

.stat {
    font-weight: bold;
    color: #887788;
}

.stat-value {
    font-weight: lighter;
    font-size: 36px;
}
