.trigger-container {
    border: 1px solid #c1c1c1;
    padding: 10px;
}
.trigger-image {
    float: left;
}

.trigger-text {
    float: left;
    padding-left: 50px
}

.trigger-text p, .trigger-text h2 {
    text-align: left;
}

.cta {
    margin-top: 30px;
    color: #887788;
    font-style: italic;
}
