.imprint-container {
    text-align: center;
}

.imprint-container hr {
    box-shadow: none;
    width: 300px;
}

hr {
    border: 1px solid #ddd;
}
